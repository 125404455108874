window.htmx = require("htmx.org")

import "./styles/reset.css"
import "./styles/functional.css"

import "./fonts/dolly.css"
import "./fonts/elza.css"

import "./styles/vars.css"
import "./styles/fonts.css"
import "./styles/icons.css"
import "./styles/buttons.css"
import "./styles/body.css"
import "./styles/header.css"
import "./styles/footer.css"
import "./styles/menu.css"
import "./styles/embeds.css"
import "./styles/forms.css"
import "./styles/prose.css"
import "./styles/details.css"

import "./styles/plugins.css"
import "./styles/bricks.css"
import "./styles/pagination.css"
import "./styles/teasers.css"
import "./styles/rubric.css"
import "./styles/calendar.css"
import "./styles/agenda.css"
import "./styles/parish.css"
import "./styles/article.css"
import "./styles/edit-tools.css"

import "./dashboard/base.css"
import "./dashboard/login.css"
import "./dashboard/objects.css"

import "./dashboard/rrule.js"

import { initCopyrightRequired } from "./js/copyright.js"
import { setDetailsHeights } from "./js/details.js"
import { initMap } from "./js/map.js"
import { previewForm } from "./js/preview-form.js"
import { sortableUpdatesWidget } from "./js/sortable-updates-widget.js"
import { getCSSVariableValue, onReady, qs, qsa } from "./js/utils.js"

onReady(() => {
  document.body.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      window["menu-toggle"].checked = false
    }
  })
})

onReady(() => {
  // need to calc size before handleScroll
  const items = qsa("[data-sized]")
  const initWidthHeader = () => {
    for (const item of items) {
      item.style.removeProperty("--initial-width")
      item.style.setProperty("--initial-width", `${item.offsetWidth}px`)
    }
  }

  if (items.length) {
    window.addEventListener("resize", initWidthHeader, true)
    initWidthHeader()
  }

  const handleScroll = () => {
    document.body.classList.toggle("body--scrolled", window.scrollY > 16)
  }

  handleScroll()
  window.addEventListener("scroll", handleScroll, { passive: true })
})

onReady(initMap)

onReady(() => {
  const button = qs("[data-scroll-down]")
  if (button) {
    const headerLead = qs(".header--lead")
    button.addEventListener("click", (e) => {
      e.preventDefault()

      const headerLeadHeight = headerLead.getBoundingClientRect().height
      const headerHeight = Number.parseInt(
        getCSSVariableValue("--header-height-full"),
        10,
      )

      if (headerLeadHeight && headerHeight) {
        const offset = headerLeadHeight - headerHeight

        window.scrollTo({
          top: offset,
          behavior: "smooth",
        })
      }
    })
  }
})

onReady(() => {
  setDetailsHeights("details")
})

onReady(() => {
  const hideMessages = (el) => {
    el.classList.add("messages--hidden")

    el.addEventListener("transitionend", () => {
      el.remove()
    })
  }

  for (const el of qsa(".messages")) {
    setTimeout(() => {
      hideMessages(el)
    }, 7500)
  }
})

onReady(initCopyrightRequired, true)
onReady(sortableUpdatesWidget, true)
onReady(previewForm, true)

const handleDateAndTimeInputs = () => {
  const dateInputs = document.querySelectorAll(
    'input[type="date"], input[type="time"]',
  )
  for (const input of dateInputs) {
    if (input.value) {
      input.classList.remove("no-value")
    } else {
      input.classList.add("no-value")
    }

    input.addEventListener("change", (ev) => {
      if (ev.target.value) {
        ev.target.classList.remove("no-value")
      } else {
        ev.target.classList.add("no-value")
      }
    })
  }
}

const initDateAndTimeHandler = () => {
  document.addEventListener("htmx:afterSwap", () => {
    handleDateAndTimeInputs()
  })

  document.addEventListener("htmx:beforeSwap", () => {
    const dateInputs = qsa('input[type="date"], input[type="time"]')
    for (const input of dateInputs) {
      const clone = input.cloneNode(true)
      input.replaceWith(clone)
    }
  })
}

onReady(() => {
  initDateAndTimeHandler()
})
