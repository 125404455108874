export function initCopyrightRequired() {
  const forms = document.querySelectorAll(".form")
  for (const form of forms) {
    const copyrights = form.querySelectorAll("[data-copyright-for]")
    for (const copyright of copyrights) {
      const selector = copyright.dataset.copyrightFor
      const input = form.querySelector(`[name="${selector}"]`)
      if (input) {
        const fieldset = input.closest(".field")
        const preview = fieldset.querySelector(".imagefield-preview-image")
        const reset = fieldset.querySelector('[type="checkbox"]')

        checkCopyrightVSInput(copyright, input, reset, preview)
        input?.addEventListener("change", (_e) =>
          checkCopyrightVSInput(copyright, input, reset, preview),
        )
        reset?.addEventListener("change", (_e) =>
          checkCopyrightVSInput(copyright, input, reset, preview),
        )
        window.addEventListener("check-copyright", (_e) => {
          checkCopyrightVSInput(copyright, input, reset, preview)
        })
      }
    }
  }
}

function checkCopyrightVSInput(copyright, input, reset, preview) {
  console.log("check")
  let required = false
  if (
    (preview?.getAttribute("src") || input.value) &&
    !reset?.checked &&
    !copyright.closest(".field")?.classList?.contains("hidden-by-selection")
  ) {
    required = true
  }
  copyright.toggleAttribute("required", required)
}
