import register from "preact-custom-element"
import { useEffect, useMemo, useState } from "preact/hooks"

const optionsToString = (options) => {
  if (!options.freq) return ""
  const parts = [`FREQ=${options.freq}`]

  if (options.until) {
    parts.push(`UNTIL=${options.until.replace(/-/g, "")}T235959Z`)
  }

  return parts.join(";")
}

const rruleToOptions = (value) => {
  const options = { freq: "", until: null }
  let v
  if ((v = value.match(/FREQ=(WEEKLY;INTERVAL=2|[A-Z]+)/))) {
    options.freq = v[1]
  }
  if ((v = value.match(/UNTIL=([0-9]{4})([0-9]{2})([0-9]{2})T/))) {
    options.until = `${v[1]}-${v[2]}-${v[3]}`
  }
  return options
}

let __id = 0

const RRuleWidget = ({ start, target }) => {
  const startEl = useMemo(() => document.getElementById(start), [start])
  const targetEl = useMemo(() => document.getElementById(target), [target])

  const [widgetId] = useState(() => `rrule${++__id}`)

  const [startDate, setStartDate] = useState(() => {
    return startEl.value ? new Date(startEl.value) : new Date()
  })
  useEffect(() => {
    const listener = () => {
      setStartDate(startEl.value ? new Date(startEl.value) : new Date())
    }
    startEl.addEventListener("input", listener)
    return () => {
      startEl.removeEventListener("input", listener)
    }
  }, [startEl])

  const [options, setOptions] = useState(() => rruleToOptions(targetEl.value))

  useEffect(() => {
    targetEl.value = optionsToString(options)
  })

  console.debug("RRuleWidget", options)

  const choices = [
    ["", "Keine Wiederholung"],
    ["DAILY", "Täglich"],
    ["WEEKLY", "Wöchentlich"],
    ["WEEKLY;INTERVAL=2", "Alle zwei Wochen"], // FIXME restore doesn't work
    ["MONTHLY", `Monatlich am ${startDate.getDate()}. Tag`], // Am gleichen Tag des Monats
    // TODO monatlich am 2. Montag (o.ä.)
  ]

  return (
    <>
      <div class="field">
        <label for={`${widgetId}_freq`}>Wiederholung</label>
        <select
          id={`${widgetId}_freq`}
          value={options.freq}
          onInput={(e) => {
            setOptions((o) => ({ ...o, freq: e.target.value }))
          }}
        >
          {choices.map(([value, title]) => (
            <option value={value} key={value}>
              {title}
            </option>
          ))}
        </select>
      </div>
      {options.freq ? (
        <div class="field">
          <label for={`${widgetId}_until`}>Wiederholen bis</label>
          <input
            id={`${widgetId}_until`}
            type="date"
            value={options.until}
            onInput={(e) => {
              setOptions((o) => ({ ...o, until: e.target.value }))
            }}
          />
        </div>
      ) : null}
    </>
  )
}

register(RRuleWidget, "rrule-widget", ["for"], { shadow: false })
