import { qs } from "./utils"

export function previewForm() {
  const form = document.querySelector(".form--preview")
  if (form) {
    const layout = form.querySelector("#id_layout")

    function hideFor(selection) {
      for (const field of form.querySelectorAll(".hidden-by-selection")) {
        field.classList.remove("hidden-by-selection")
      }

      for (const inputField of form.querySelectorAll(
        `.hide-for--${selection}`,
      )) {
        inputField.value = ""
        const field = inputField.closest(".field")
        if (field) field.classList.add("hidden-by-selection")
      }

      window.dispatchEvent(new Event("check-copyright"))
    }

    hideFor(qs("input:checked", layout)?.value)
    layout.addEventListener("change", (e) => {
      hideFor(e.target?.value)
    })
  }
}
