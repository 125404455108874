import { getCSSVariableValue, qs } from "./utils"

export const initMap = () => {
  const map = qs("[data-map]")
  const pill = qs("[data-pill]")
  if (map && pill) {
    map.addEventListener("mousemove", (e) => {
      e.stopPropagation()
      const target = e.target
      const name = target.dataset.region

      if (name) {
        const rect = map.getBoundingClientRect()
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top
        const mapWidth = rect.width

        pill.classList.add("show")
        let transform = "translate(0%,-50%)"

        if (x > mapWidth / 2) {
          transform = "translate(-100%,-50%)"
          pill.style.left = `${x - 24}px`
          pill.style.top = `${y}px`
        } else {
          pill.style.left = `${x + 24}px`
          pill.style.top = `${y}px`
        }
        pill.style.transform = transform
        pill.textContent = name
      } else {
        pill.classList.remove("show")
      }
    })

    map.addEventListener("mouseleave", (e) => {
      e.stopPropagation()
      pill.classList.remove("show")
    })

    map.addEventListener("click", (e) => {
      e.stopPropagation()
      const select = document.getElementById("id_region")
      const target = e.target
      const id = target.dataset.regionId
      if (id) {
        pill.classList.remove("show")
        select.value = id
        select.dispatchEvent(new Event("input", { bubbles: true }))

        const container = select.closest(".search")
        const headerHeight = Number.parseInt(
          getCSSVariableValue("--header-height-full"),
          10,
        )

        if (container && headerHeight) {
          const containerRect = container.getBoundingClientRect()
          const containerTop = containerRect.top + window.scrollY - 16

          window.scrollTo({
            top: containerTop - headerHeight,
            behavior: "smooth",
          })
        }
      }
    })

    // window.addEventListener("htmx:afterSettle", (e) => {
    //   console.log("e", e)
    //   const queryString = window.location.search
    //   const urlParams = new URLSearchParams(queryString)
    //   const region = urlParams.get("region")
    //   if (region) {
    //     const data = qs(`[data-region-id="${region}"]`)
    //     data.classList.add("map__region--active")
    //   }
    // })
  }
}
